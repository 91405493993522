import React from "react"
import styled from "styled-components"
import Fuji from "./fuji.svg"
// ______________________________________________________
//
type Props = {
  className?: string
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div className={props.className}>
    <Fuji />
  </div>
)
// ______________________________________________________
//
const StyledComponent = styled(Component)`
  display: flex;
  justify-content: space-between;
`
// ______________________________________________________
//
export default StyledComponent
