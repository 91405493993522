import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Logo from "./logo.svg"
import TermPC from "./term_pc.svg"
import TermSP from "./term_sp.svg"
// ______________________________________________________
//
type Props = {
  className?: string
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div className={props.className}>
    <p className="logo">
      <Logo />
    </p>
    <p className="term-pc">
      <TermPC />
    </p>
    <p className="term-sp">
      <TermSP />
    </p>
  </div>
)
// ______________________________________________________
//
const pcStyle = `
> .logo {
  margin-top: 80px;
}
> .term-sp {
  display: none;
}
> .term-pc {
  padding: 20px;
}
> .btnBuyTicket {
  width: 263px;
}
`
const spStyle = `
> .logo {
  margin-top: 80px;
  svg {
    width: 260px;
  }
}
> .term-pc {
  display: none;
}
> .term-sp svg {
  padding: 10px;
}
> .btnBuyTicket {
  width: 280px;
  margin-top: 100px;
}
`
const StyledComponent = styled(Component)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  > .logo {
    line-height: 0;
  }
  ${media.lessThan("medium")`${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
