import React from "react"
import { useMemo } from "react"
import styled from "styled-components"
// ______________________________________________________
//
type ContainerProps = {
  className?: string
  radius: number
  ratios: number[]
}
type Props = {
  className?: string
  radius: number
  itemStyles: {
    height: number
    width: string
  }[]
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div className={props.className}>
    {props.itemStyles.map((itemStyle, i) => (
      <span key={i} style={itemStyle} />
    ))}
  </div>
)
// ______________________________________________________
//
const StyledComponent = styled(Component)`
  display: flex;
  @keyframes fadeIn {
    0% {
      background-color: rgba(0, 0, 0, 0);
      /* background-color: #05ffb8; */
    }
    20% {
      background-color: rgba(0, 0, 0, 0);
      /* background-color: #05ffb8; */
    }
    35% {
      background-color: #4cd9ff;
      /* background-color: #05ffb8; */
    }
    50% {
      background-color: #4e38ff;
      /* background-color: #05ffb8; */
    }
    65% {
      background-color: #05ffb8;
      /* background-color: #05ffb8; */
    }
    80% {
      background-color: rgba(0, 0, 0, 0);
      /* background-color: #05ffb8; */
    }
    100% {
      background-color: rgba(0, 0, 0, 0);
      /* background-color: #05ffb8; */
    }
  }
  span {
    display: block;
    margin: ${props => props.radius / 2}px;
    animation-name: fadeIn;
    animation-iteration-count: infinite;
  }
`
// ______________________________________________________
//
const Container: React.FC<ContainerProps> = props => {
  const total = useMemo(() => props.ratios.reduce((a, b) => a + b), [])
  const itemStyles = useMemo(
    () =>
      props.ratios.map(ratio => ({
        height: props.radius,
        width: `${(ratio / total) * 100}%`,
        borderRadius: props.radius,
        animationDuration: `${(ratio / total) * 20}s`,
      })),
    []
  )
  return (
    <StyledComponent
      className={props.className}
      radius={props.radius}
      itemStyles={itemStyles}
    />
  )
}
export default Container
