import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Item from "./item"
import { ContentfulSpeaker, Maybe } from "@/types"
// ______________________________________________________
//
type Props = {
  className?: string
  speakers: Maybe<ContentfulSpeaker>[]
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div className={props.className}>
    {props.speakers.map((speaker, index) => {
      if (!speaker) return null
      return <Item speaker={speaker} key={index} />
    })}
  </div>
)
// ______________________________________________________
//
const pcStyle = `
`
const spStyle = `
padding: 20px;
`
const StyledComponent = styled(Component)`
  ${media.lessThan("medium")`${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
