import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Town1 from "./town1.svg"
import Town2 from "./town2.svg"
import Town3 from "./town3.svg"
// ______________________________________________________
//
type Props = {
  className?: string
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div className={props.className}>
    <div className="town1">
      <Town1 />
    </div>
    <div className="town2">
      <Town2 />
    </div>
    <div className="town3">
      <Town3 />
    </div>
  </div>
)
// ______________________________________________________
//
const pcStyle = `
display: flex;
justify-content: space-between;
align-items: flex-end;
`
const spStyle = `
> .town1 {
  position: absolute;
  bottom: 15px;
  left: -60px;
  svg {
    width: 220px;
  }
}
> .town2 {
  position: absolute;
  bottom: -10px;
  right: -60px;
  svg {
    width: 370px;
  }
}
> .town3 {
  display: none;
}
`
const StyledComponent = styled(Component)`
  ${media.lessThan("medium")`${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
