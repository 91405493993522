import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import { UseToggleLanguage } from "@components/hooks"
import HeadTSConfJP from "@components/common/headTSConfJP"
import SelectLanguages from "@components/common/selectLanguages"
import Main from "./main"
import Town from "./town"
import Fuji from "./fuji"
import Clouds from "./clouds"
// ______________________________________________________
//
type Props = {
  className?: string
} & UseToggleLanguage
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div className={props.className}>
    <Fuji className="fuji" />
    <Town className="town" />
    <Clouds className="clouds" />
    <Main className="main" />
    <HeadTSConfJP className="tsconfjp" />
    <SelectLanguages
      className="selectLanguages"
      lang={props.lang}
      toggleToEN={props.toggleToEN}
      toggleToJA={props.toggleToJA}
    />
  </div>
)
// ______________________________________________________
//
const pcStyle = `
height: 270px;
> .fuji {
  right: 0;
  bottom: 0;
}
> .main {
  margin-top: 20px;
}
`
const spStyle = `
height: 240px;
> .fuji {
  width: 486px;
  right: -130px;
  bottom: 60px;
}
> .selectLanguages {
  display: none;
}
`
const StyledComponent = styled(Component)`
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    180deg,
    rgba(31, 28, 180, 0) 0%,
    rgba(31, 28, 180, 0.35) 100%
  );
  > .fuji {
    position: absolute;
  }
  > .town {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
  }
  > .clouds {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  > .tsconfjp {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  > .main {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
  }
  > .selectLanguages {
    position: absolute;
    top: 0;
    right: 20px;
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background-color: ${({ theme }) => theme.colors.primary};
  }
  ${media.lessThan("medium")`${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
