import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import { ContentfulSpeaker } from "@/types"
import SpeakerSNS from "@components/common/speakerSns"
// ______________________________________________________
//
type Props = {
  className?: string
  speaker: ContentfulSpeaker
}
// ______________________________________________________
//
const Component: React.FC<Props> = ({ className, speaker }) => (
  <div className={className}>
    <img
      className="avatar"
      // @ts-ignore
      loading="lazy"
      src={`${speaker.avatar?.file?.url ?? ""}?w=220&h=220`}
    />
    <div className="description">
      <h3 className="name">{speaker.name}</h3>
      <p className="bio">{speaker.bio?.bio}</p>
      <SpeakerSNS
        githubAccount={speaker.githubAccount}
        twitterAccount={speaker.twitterAccount}
      />
    </div>
  </div>
)
// ______________________________________________________
//
const pcStyle = `
display: flex;
> .avatar {
  width: 220px;
  height: 220px;
  min-width: 220px;
  min-height: 220px;
  margin-bottom: 10px;
}
> .description {
  margin-left: 50px;
  > .name {
    margin-bottom: 10px;
    font-size: 3.6rem;
    line-height: 1.2;
  }
  > .bio {
    margin-bottom: 20px;
    font-size: 1.6rem;
    line-height: 1.8;
  }
}
`
const spStyle = `
> .avatar {
  display: block;
  width: 172px;
  height: 172px;
  min-width: 172px;
  min-height: 172px;
  margin: 0 auto 20px;
}
> .description {
  > .name {
    margin-bottom: 10px;
    font-size: 2.6rem;
    line-height: 1.2;
  }
  > .bio {
    margin-bottom: 20px;
    font-size: 1.6rem;
    line-height: 1.6;
  }
}
`
const StyledComponent = styled(Component)`
  > .description {
    > .bio {
      font-weight: bold;
      font-family: "Roboto", sans-serif;
      text-justify: inter-character;
    }
  }
  ${media.lessThan("medium")`${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
