import React from "react"
import styled from "styled-components"
import GitHub from "@components/common/svg/github.svg"
import Twitter from "@components/common/svg//twitter.svg"
// ______________________________________________________
//
export type Props = {
  className?: string
  githubAccount?: string | null | undefined
  twitterAccount?: string | null | undefined
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div className={props.className}>
    {props.githubAccount && (
      <p className="github">
        <a
          href={`https://github.com/${props.githubAccount}`}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={props.githubAccount}
        >
          <GitHub />
        </a>
      </p>
    )}
    {props.twitterAccount && (
      <p className="twitter">
        <a
          href={`https://twitter.com/${props.twitterAccount}`}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={props.twitterAccount}
        >
          <Twitter />
        </a>
      </p>
    )}
  </div>
)
// ______________________________________________________
//
const StyledComponent = styled(Component)`
  display: flex;
  > * {
    margin-right: 10px;
  }
`
// ______________________________________________________
//
export default StyledComponent
