import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Button from "@components/common/button"
// ______________________________________________________
//
type Props = {
  className?: string
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div className={props.className}>
    <Button
      className="btnBackToTop"
      label="BACK TO TOP"
      href={"/2020"}
      height={70}
      fontSize={22}
      color="light"
      backgroundColor="primary"
    />
  </div>
)
// ______________________________________________________
//
const pcStyle = `
padding: 40px 0 60px;
`
const spStyle = `
padding: 20px 0 40px;
`
const StyledComponent = styled(Component)`
  > * {
    width: 266px;
    margin: 0 auto;
  }
  ${media.lessThan("medium")`${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
