import React from "react"
import { filterByLang, getFormattedDateFromString } from "@components/utils"
import { useToggleLanguage } from "@components/hooks"
import Layout from "@components/layouts"
import Meta from "@components/layouts/meta"
import Template from "@components/templates/2020/sessions"
import { SessionTemplateContext } from "@/gatsby-node/createSessions"
// ______________________________________________________
//
type Props = {
  pageContext: SessionTemplateContext
}
// ______________________________________________________
//
const Component: React.FC<Props> = ({ pageContext }) => {
  const { lang, toggleToEN, toggleToJA } = useToggleLanguage()
  const node = pageContext.edges.filter(filterByLang(lang))[0]?.node
  const title = node.name || ""
  const pageTitle = `${title} | ${pageContext.site?.siteMetadata?.title}`
  const startsAt = getFormattedDateFromString(node.startsAt)
  const endsAt = getFormattedDateFromString(node.endsAt)
  const sessionTimeLabel = `${startsAt} - ${endsAt}`
  const speakers = node.speakers ?? []
  const body = node.description?.childMarkdownRemark?.html ?? ""
  const ogpImage = `https://tsconf.jp/ogpimages/${pageContext.slug}/${lang}.png`
  return (
    <Layout env={pageContext.site?.siteMetadata?.env ?? ""}>
      <Meta title={pageTitle} ogpImage={ogpImage} />
      <Template
        {...pageContext}
        title={title}
        lang={lang}
        toggleToEN={toggleToEN}
        toggleToJA={toggleToJA}
        sessionTimeLabel={sessionTimeLabel}
        speakers={speakers}
        body={body}
      />
    </Layout>
  )
}
// ______________________________________________________
//
export default Component
