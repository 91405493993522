import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Unit from "./unit"
// ______________________________________________________
//
type Props = {
  className?: string
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div className={props.className}>
    <Unit radius={8} ratios={[2, 4, 2]} />
    <Unit radius={12} ratios={[3, 5, 2]} />
    <Unit radius={12} ratios={[4, 4, 1]} />
    <Unit radius={8} ratios={[2, 4, 5]} />
    <Unit radius={8} ratios={[3, 5, 2]} />
    <Unit radius={12} ratios={[3, 6, 5]} />
    <Unit radius={12} ratios={[4, 4, 1]} />
    <Unit radius={8} ratios={[2, 4, 5]} />
  </div>
)
// ______________________________________________________
//
const pcStyle = `
> * {
  width: 30%;
  &:nth-child(1) {
    top: 60px;
    left: -10%;
  }
  &:nth-child(2) {
    top: 80px;
    left: -8%;
  }
  &:nth-child(3) {
    top: 103px;
    left: -4%;
  }
  &:nth-child(4) {
    top: 130px;
    left: -2%;
  }
  &:nth-child(5) {
    top: 140px;
    right: -10%;
  }
  &:nth-child(6) {
    top: 170px;
    right: -8%;
  }
  &:nth-child(7) {
    top: 193px;
    right: -4%;
  }
  &:nth-child(8) {
    top: 220px;
    right: -2%;
  }
}
`
const spStyle = `
transform: scale(.6);
> * {
  width: 100%;
  &:nth-child(1) {
    top: -80px;
    left: -100%;
  }
  &:nth-child(2) {
    top: -60px;
    left: -90%;
  }
  &:nth-child(3) {
    top: -37px;
    left: -80%;
  }
  &:nth-child(4) {
    top: -10px;
    left: -70%;
  }
  &:nth-child(5) {
    top: 200px;
    right: -100%;
  }
  &:nth-child(6) {
    top: 220px;
    right: -90%;
  }
  &:nth-child(7) {
    top: 243px;
    right: -80%;
  }
  &:nth-child(8) {
    top: 270px;
    right: -70%;
  }
}
`
const StyledComponent = styled(Component)`
  > * {
    position: absolute;
  }
  ${media.lessThan("medium")`${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
