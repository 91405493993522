import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Title from "@components/common/title/h3"
import Clock from "./clock.svg"
import Earth from "./earth.svg"
// ______________________________________________________
//
type Props = {
  className?: string
  lang: string
  title: string
  sessionTimeLabel: string
  body: string
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div className={props.className}>
    <Title className="title" lang={props.lang}>
      {props.title}
    </Title>
    <p className="sessionTimeLabel">
      <Clock />
      {props.sessionTimeLabel}
    </p>
    <p className="interpretation">
      <Earth />
      {props.lang === "ja" && "同時通訳あり"}
      {props.lang === "en-US" && "With simultaneous interpretation"}
    </p>
    <article dangerouslySetInnerHTML={{ __html: props.body }} />
  </div>
)
// ______________________________________________________
//
const pcStyle = `
padding: 30px;
> .title {
  margin-bottom: 20px;
}
> .sessionTimeLabel,
> .interpretation {
  display: flex;
  margin: 10px 0;
  font-weight: bold;
  svg {
    margin-right: 10px;
  }
}
article {
  margin-top: 30px;
}
`
const spStyle = `
padding: 20px;
> .title {
  margin-bottom: 20px;
}
> .sessionTimeLabel,
> .interpretation {
  display: flex;
  margin: 10px 0;
  font-size: 1.8rem;
  font-weight: bold;
  svg {
    margin-right: 10px;
  }
}
`
const StyledComponent = styled(Component)`
  background-color: rgba(196, 196, 196, 0.06);
  article {
    a {
      color: ${({ theme }) => theme.colors.sky};
    }
    h1 {
      font-size: 2.4rem;
    }
    h2 {
      font-size: 2.2rem;
    }
    h3 {
      font-size: 2rem;
    }
    h4 {
      font-size: 1.8rem;
    }
    h5 {
      font-size: 1.6rem;
    }
    h5 {
      font-size: 1.4rem;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 40px;
      margin-bottom: 10px;
      font-weight: bold;
    }
    ul {
      margin: 20px 0;
      li {
        &::before {
          content: "・";
        }
        padding-left: 1em;
        text-indent: -1em;
        line-height: 1.8;
      }
    }
    p {
      margin: 20px 0;
      font-size: 1.6rem;
      line-height: 1.8;
    }
  }
  ${media.lessThan("medium")`${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
