import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Speakers from "./speakers"
import Body from "./body"
import { ContentfulSpeaker, Maybe } from "@/types"
// ______________________________________________________
//
type Props = {
  className?: string
  title: string
  body: string
  lang: string
  speakers: Maybe<ContentfulSpeaker>[]
  sessionTimeLabel: string
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => (
  <div className={props.className}>
    <Speakers className="speakers" speakers={props.speakers} />
    <Body
      className="body"
      lang={props.lang}
      title={props.title}
      sessionTimeLabel={props.sessionTimeLabel}
      body={props.body}
    />
  </div>
)
// ______________________________________________________
//
const pcStyle = `
max-width: 970px;
padding: 40px 40px 0px 40px;
margin: 0 auto;
> .speakers {
  margin-bottom: 40px;
}
`
const spStyle = `
> .speakers {
  margin-bottom: 20px;
  padding-bottom: 20px;
}
`
const StyledComponent = styled(Component)`
  ${media.lessThan("medium")`${spStyle}`}
  ${media.greaterThan("medium")`${pcStyle}`}
`
// ______________________________________________________
//
export default StyledComponent
